<template>
  <div>
    <b-card>
      <b-list-group>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Firma Adı
          </div>
          <div class="text-muted">
            {{ dataItem.company }}
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Yetkili Kişi
          </div>
          <div class="text-muted">
            {{ dataItem.fullname }}
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Telefon
          </div>
          <div class="text-muted">
            {{ dataItem.phone }}
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            E-Posta
          </div>
          <div class="text-muted">
            {{ dataItem.email }}
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Mesajı
          </div>
          <div class="text-muted">
            {{ dataItem.message }}
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Ülke
          </div>
          <div class="text-muted">
            {{ dataItem.country }}
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Şehir
          </div>
          <div class="text-muted">
            {{ dataItem.city }}
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Başvuru Tarihi
          </div>
          <div class="text-muted">
            {{ moment(dataItem.created).format('LLLL') }}
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.modified">
          <div class="font-weight-bold text-primary">
            Başvuru Güncelleme
          </div>
          <div>{{ dataItem.username }}</div>
          <div class="text-muted">
            {{ moment(dataItem.modified).format('LLLL') }}
          </div>
        </b-list-group-item>
      </b-list-group>
      <hr>
      <b-form-group
        label="Durum"
        label-for="status"
      >
        <v-select
          id="status"
          v-model="dataItem.status"
          :options="statuses"
          label="title"
          :reduce="item => item.id"
          placeholder="Durum"
        />
      </b-form-group>
    </b-card>
    <div class="d-flex justify-content-between">
      <b-button
        variant="primary"
        :disabled="!dataItem.status"
        @click="submitForm"
      >
        <FeatherIcon icon="SaveIcon" />
        Kaydet
      </b-button>
      <b-button
        variant="danger"
        @click="removeData(dataItem.id)"
      >
        <FeatherIcon icon="TrashIcon" />
        Başvuruyu Sil
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BCard, BListGroup, BListGroupItem, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'RequestView',
  components: {
    BCard, BListGroup, BListGroupItem, BFormGroup, BButton, vSelect,
  },
  computed: {
    statuses() {
      return [
        { id: null, title: 'Beklemede' },
        { id: '1', title: 'Onaylandı' },
        { id: '0', title: 'Reddedildi' },
      ]
    },
    dataItem() {
      return this.$store.getters['registerRequests/dataItem']
    },
    saveData() {
      return this.$store.getters['registerRequests/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push('/app/customers/accounts/requests')
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('registerRequests/getDataItem', this.$route.params.id)
    },
    submitForm() {
      if (this.dataItem.status) {
        this.$store.dispatch('registerRequests/saveData', this.dataItem)
      }
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('registerRequests/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
